{
  "menu.membership": "会员",
  "menu.purchase": "购买",
  "menu.residential": "住宅代理",
  "menu.proxytester": "代理测试器",
  "menu.myorders": "我的订单",
  "menu.profile": "资料",
  "menu.profile.confirmlogout": "确定退出登录？",
  "menu.profile.logout": "退出登录",
  "menu.profile.changeyouremail": "更改您的电子邮件",
  "menu.profile.linkyouremail": "链接您的电子邮箱",
  "menu.profile.verification": "您的电子邮件中还没有收到验证码？",
  "menu.profile.clickhereto": "点击这里",
  "menu.profile.resend": "重新发送",
  "menu.profile.theemail": "电子邮件。",
  "menu.profile.link": "链接",
  "menu.profile.verify1": "一封包含验证邮箱具体步骤的邮件已经发送到您的邮箱。",
  "menu.profile.verify2": "您需要验证您的电子邮箱来激活您的账户",
  "menu.profile.resend.success": "重新发送成功",
  "menu.profile.remail.unsuccess": "邮箱更改失败",
  "menu.profile.remail.success": "邮箱修改成功",
  "menu.extra.community": "社区/技术支持",
  "membership.subscribe": "订阅",
  "membership.desc1.c1": "价值 46 美元的免费流量",
  "membership.desc1.c2": "专属折扣优惠",
  "membership.desc1.c3": "Dragon家族产品抽奖",
  "membership.desc1.c4": "专属合作品牌抽奖",
  "membership.desc2.c1": "价值 108 美元的免费流量",
  "membership.desc2.c2": "专属折扣优惠",
  "membership.desc2.c3": "更高赢得Dragon家族产品中奖机会",
  "membership.desc2.c4": "专属合作品牌抽奖",
  "membership.plan1.name": "黄金会员",
  "membership.plan2.name": "钻石会员",
  "purchase.totalamount": "总金额",
  "purchase.paymentmethod": "支付方式",
  "purchase.buy": "购买",
  "purchase.apply": "验证",
  "purchase.discountcode": "折扣码",
  "purchase.product": "产品",
  "purchase.enterprise.title": "如果您需要企业级流量包，请联系",
  "purchase.buy.reason.code": "折扣码不存在",
  "purchase.buy.reason.goods": "请选择商品",
  "purchase.buy.reason.paymethod": "请选择付款方式",
  "purchase.buy.reason.success": "申请成功！",
  "purchase.buy.notify": "您购买的产品是一个数字产品，我们的代理服务将立即在您的仪表板上提供。 这意味着任何订单都不能退还或退款。所有的销售都是100%的最终销售。 在您决定购买之前，请确保您同意我们的退款政策。 如果您有任何疑问，欢迎在我们的Discord服务器上打开工单。",
  "purchase.discount.reason.goods": "请先选择产品",
  "purchase.discount.reason.code": "请输入折扣码",
  "common.warning": "警告",
  "common.agree": "同意",
  "common.confirm": "确认",
  "common.cancel": "取消",
  "common.save": "保存",
  "common.reason.unknown": "未知错误",
  "common.form.pleaseselect": "请选择",
  "common.form.pleaseinput": "请输入",
  "common.form.isee": "我明白了。",
  "common.overview": "概览",
  "residential.panel.notimelimit": "无时间限制",
  "residential.panel.expire": "过期",
  "residential.panel.resetpassword": "重置密码",
  "residential.panel.remaining": "剩余",
  "residential.panel.used": "已用",
  "residential.panel.alltime": "所有",
  "residential.panel.week": "周",
  "residential.panel.month": "月",
  "residential.panel.reason.planName": "无效的计划名称！",
  "residential.panel.reason.planId": "无效的计划ID！",
  "residential.panel.reason.success": "更改稍后生效... 请不要立即测试您的代理",
  "residential.proxy.generateproxies": "生成代理",
  "residential.proxy.shuffle": "打乱",
  "residential.proxy.copy": "复制",
  "residential.proxy.download": "下载",
  "residential.proxy.generatedsuccessfully": "生成成功",
  "residential.proxy.savesuccessfully!": "保存成功",
  "residential.proxy.copysuccess": "复制成功",
  "residential.proxy.gen.usedefaultprofile": "使用默认配置文件",
  "residential.proxy.gen.defaultprofile": "默认配置",
  "residential.proxy.gen.profile": "产品",
  "residential.proxy.gen.plan": "方案",
  "residential.proxy.gen.country": "国家",
  "residential.proxy.gen.rotate": "旋转",
  "residential.proxy.gen.quantity": "数量",
  "residential.proxy.gen.saveasdefault": "保存为默认设置",
  "residential.proxy.gen.generate": "生成",
  "residential.proxy.gen.rotate.notrotate": "不轮换",
  "residential.proxy.gen.rotate.rotateperrequest": "按请求轮换",
  "residential.proxy.gen.rotate.confirmdelete": "确定删除？",
  "tester.title": "代理测试器",
  "tester.desc": "Dragon Proxies 测试程序是由Dragon 团队开发的，所有Dragon 用户都可免费下载。 20+测试网站选择，您也可以选择自定义网站测试，支持 HTTP/HTTPS/Sock5 协议。",
  "order.title": "我的订单",
  "order.purchase": "购买",
  "order.table.time": "时间",
  "order.table.plan": "套餐",
  "order.table.product": "产品",
  "order.table.paymentmethod": "支付方式",
  "order.table.paid": "已付款",
  "order.table.action": "操作",
  "order.action.invoice": "发票",
  "order.action.download": "下载",
  "order.reason.deliveryinprogress": "交付中",
  "order.action.expired": "已过期",
  "profile.profile": "资料",
  "profile.form.firstname": "名字",
  "profile.form.lastname": "姓氏",
  "profile.form.phone": "电话号码",
  "profile.form.address": "地址",
  "profile.form.address2": "详细地址",
  "profile.form.country": "国家",
  "profile.form.city": "城市",
  "profile.form.zipcode": "邮政编码",
  "profile.form.companyname": "企业名称",
  "profile.form.companyurl": "公司 Url",
  "header.notifications": "通知",
  "footer.policy": "隐私政策",
  "footer.team": "条款及细则",
  "footer.copyright": " Dragon Power Inc 家族一员"
}
